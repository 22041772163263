import { useClientTranslation } from "@rimo/i18n/useClientTranslation";
import React, { useState } from "react";

import ChevronIconBase from "@mui/icons-material/ExpandMore";
import styled, { css } from "styled-components";

import { PrimaryButton, SecondaryButton } from "../../components/landing/voice/Button";
import { useResponsive } from "../../hooks/useResponsive";
import { RimoVoiceGenericLPProps } from "./RimoVoiceGenericLP";
import { MOBILE_BREAKPOINT } from "./shared";
import { LocaleLink } from "../../components/LocaleLink";
import { usePathname } from "next/navigation";

const DATA = {
  writing: {
    ctaText: "無料トライアル",
    ctaLink: "/about",
    ctaLinkHash: undefined,
  },
  proceedings: {
    ctaText: "資料ダウンロード",
    ctaLink: undefined,
    ctaLinkHash: "contact-form",
  },
  training: {
    ctaText: "お問い合わせ",
    ctaLink: undefined,
    ctaLinkHash: "contact-form",
  },
  businessAnalysis: {
    ctaText: "お問い合わせ",
    ctaLink: undefined,
    ctaLinkHash: "contact-form",
  },
  userResearch: {
    ctaText: "無料トライアル",
    ctaLink: "/about",
    ctaLinkHash: undefined,
  },
  transcription: {
    ctaText: "無料トライアル",
    ctaLink: "/about",
    ctaLinkHash: undefined,
  },
};
export const Header: React.FC<Pick<RimoVoiceGenericLPProps, "target"> & { hideMobileCTA?: boolean }> = (props) => {
  const { customT } = useClientTranslation();

  const { isMobile } = useResponsive();
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const data = DATA[props.target];
  const pathname = usePathname() || undefined;
  const isLPPage = pathname?.startsWith("/usecases");
  const ctaPathname = isLPPage ? pathname : "/usecases";
  return (
    <>
      <MobileMenu $visible={mobileMenuVisible}>
        <LocaleLink href="/about/voice">
          <MenuLink>{customT("Rimo voice について")}</MenuLink>
        </LocaleLink>
        <MenuDivider1 />
        <LocaleLink href={`/about/voice_partners`}>
          <MenuLink>{customT("パートナーになる")}</MenuLink>
        </LocaleLink>
        <MenuDivider2 />
        <LocaleLink href={`/about`}>
          <MenuLink $colored={true}>{customT("ログイン")}</MenuLink>
        </LocaleLink>
        <LocaleLink href={`/about`}>
          <MenuLink $colored={true}>{customT("無料で利用開始")}</MenuLink>
        </LocaleLink>
      </MobileMenu>
      <HeaderWrapper $dropdownVisible={mobileMenuVisible}>
        <HeaderContent>
          <HeaderLeftBlock>
            <LocaleLink href="/about/voice">
              <LogoImg src="/assets/rimo_logo.svg" alt="Rimo logo" />
            </LocaleLink>
            <Voice>voice</Voice>
            <MobileMenuChevron onClick={() => setMobileMenuVisible((prev) => !prev)} $visible={mobileMenuVisible} />
          </HeaderLeftBlock>
          <HeaderRightBlock>
            <HeadSecondaryButton link={ctaPathname} hash="#contact-form">
              {customT("資料請求する")}
            </HeadSecondaryButton>
            <HeadSecondaryButton link={`/about/voice_partners`}>{customT("パートナーになる")}</HeadSecondaryButton>
            <HeadSecondaryButton link={`/about`}>{customT("ログイン")}</HeadSecondaryButton>
            {/* <HeadDivider /> */}
            {isMobile && !props.hideMobileCTA ? (
              <HeadPrimaryButton link={isLPPage ? data.ctaLink : ctaPathname} hash={data.ctaLinkHash}>
                {customT(data.ctaText)}
              </HeadPrimaryButton>
            ) : !isMobile ? (
              <HeadPrimaryButton link={isLPPage ? data.ctaLink : ctaPathname} hash={data.ctaLinkHash}>
                {customT(data.ctaText)}
              </HeadPrimaryButton>
            ) : null}
          </HeaderRightBlock>
        </HeaderContent>
      </HeaderWrapper>
    </>
  );
};

const HEADER_HEIGHT = 60;
const HeaderWrapper = styled.div<{ $dropdownVisible: boolean }>`
  width: 100%;
  position: absolute;
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    ${(p) => p.$dropdownVisible && `background: #fff;`}
  }
`;
const HeaderContent = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  max-width: 1080px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 30;
  @media (min-width: 1080px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0 32px;
    max-width: none;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    padding: 0 12px;
    box-sizing: border-box;
    background: transparent;
  }
`;
const MobileMenuChevron = styled(ChevronIconBase)<{ $visible: boolean }>`
  @media (min-width: ${`${MOBILE_BREAKPOINT + 1}px`}) {
    && {
      display: none;
    }
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    color: #888;
    width: 24px;
    margin-left: 4px;
    align-self: flex-end;
    transition: all 200ms ease-out;
    ${(p) => p.$visible && "transform: rotate(180deg);"}
    margin-bottom: -2px;
  }
`;
const MobileMenu = styled.div<{ $visible: boolean }>`
  ${(p) => !p.$visible && "display: none;"}
  position: absolute;
  top: ${HEADER_HEIGHT}px;
  left: 0;
  width: 100vw;
  padding-bottom: 8px;
  background: #fff;
  box-shadow:
    0px 4px 12px rgba(0, 0, 0, 0.1),
    0px 0px 0px 1px rgba(0, 0, 0, 0.02);
  z-index: 20;
  @media (min-width: ${`${MOBILE_BREAKPOINT + 1}px`}) {
    display: none;
  }
`;
const MenuLink = styled.div<{ $colored?: boolean }>`
  padding: 12px 16px;
  font-size: 16px;
  line-height: 24px;
  color: ${(p) => (p.$colored ? p.theme.vars.palette.primary.main : "rgba(0, 0, 0, 0.56)")};
  ${(p) => p.$colored && "font-weight: 600;"}
`;
const MenuDivider1 = styled.hr`
  margin: 8px 16px;
  border: solid 0.5px #eee;
`;
const MenuDivider2 = styled.hr`
  margin: 8px 0;
  border: solid 0.5px #ddd;
`;
const HeaderLeftBlock = styled.div`
  display: flex;
`;
const LogoImg = styled.img`
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    height: 24px;
  }
`;
const Voice = styled.span`
  color: #888888;
  margin-left: 10px;
  font-weight: 200;
  margin-top: 4px;
  font-size: 24px;
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-left: 6px;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.5px;
  }
`;
const HeaderRightBlock = styled.div`
  display: flex;
  align-items: center;
`;
const HeaderButtonStyle = css`
  box-shadow: none;
`;
const HeadPrimaryButton = styled(PrimaryButton)`
  ${HeaderButtonStyle}
  padding: 10px 20px;
  @media (min-width: ${`${MOBILE_BREAKPOINT + 1}px`}) {
    margin-right: 20px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    font-size: 12px;
    line-height: 18px;
  }
`;
// const HeadDivider = styled.div`
//   border: solid 0.5px #c4c4c4;
//   height: 24px;
//   margin-right: 16px;
//   @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
//     display: none;
//   }
// `;
const HeadSecondaryButton = styled(SecondaryButton)`
  ${HeaderButtonStyle}
  background: none;
  padding: 10px 0;
  margin-right: 16px;
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    display: none;
  }
`;
